import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="product-customization"
export default class extends Controller {
  static values = {
    variantId: Number,
  };

  static targets = [
    "inputsContainer",
    "heart",
    "chest",
    "back",
    "leftSleeve",
    "rightSleeve",
  ];

  connect() {
    this.loadCustomizationFromStorage();
  }

  toggleInputsContainer() {
    this.inputsContainerTarget.classList.toggle("hidden");
  }

  customizationParams() {
    return {
      heart: this.heartTarget.value || "",
      chest: this.chestTarget.value || "",
      back: this.backTarget.value || "",
      leftSleeve: this.leftSleeveTarget.value || "",
      rightSleeve: this.rightSleeveTarget.value || "",
    };
  }

  persistCustomizationToStorage() {
    localStorage.setItem(
      this.storageKey(),
      JSON.stringify(this.customizationParams())
    );
  }

  loadCustomizationFromStorage() {
    let customization = localStorage.getItem(this.storageKey());

    if (customization) {
      customization = JSON.parse(customization);
      this.heartTarget.value = customization.heart;
      this.chestTarget.value = customization.chest;
      this.backTarget.value = customization.back;
      this.leftSleeveTarget.value = customization.leftSleeve;
      this.rightSleeveTarget.value = customization.rightSleeve;
    }
  }

  storageKey() {
    return `variant-${this.variantIdValue}-customization`;
  }
}
