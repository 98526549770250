import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="orders--shipping-addresses"
export default class extends Controller {
  static values = {
    addressesUrl: String,
  };

  static targets = [
    "shippingAddressSelect",
    "shippingFirstName",
    "shippingLastName",
    "shippingCompany",
    "shippingVatNumber",
    "shippingStreet",
    "shippingStreetInformation",
    "shippingZipcode",
    "shippingCity",
    "shippingState",
    "shippingCountry",
    "shippingPhone",
    "billingAddressSelect",
    "billingFirstName",
    "billingLastName",
    "billingCompany",
    "billingVatNumber",
    "billingStreet",
    "billingStreetInformation",
    "billingZipcode",
    "billingCity",
    "billingState",
    "billingCountry",
    "billingPhone",
  ];

  update() {
    const formData = new FormData(this.element);

    fetch(this.element.action, {
      method: this.element.method,
      body: formData,
    });
  }

  setBillingAddress() {
    const id = this.billingAddressSelectTarget.value;

    if (id) {
      fetch(`${this.addressesUrlValue}/${id}.json`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((address) => {
          this.billingFirstNameTarget.value = address.first_name;
          this.billingLastNameTarget.value = address.last_name;
          this.billingCompanyTarget.value = address.company;
          this.billingVatNumberTarget.value = address.vat_number;
          this.billingStreetTarget.value = address.street;
          this.billingStreetInformationTarget.value =
            address.street_information;
          this.billingZipcodeTarget.value = address.zipcode;
          this.billingCityTarget.value = address.city;
          this.billingStateTarget.value = address.state;
          this.billingCountryTarget.value = address.country;
          this.billingPhoneTarget.value = address.phone;

          this.update();
        });
    }
  }

  setShippingAddress() {
    const id = this.shippingAddressSelectTarget.value;

    if (id) {
      fetch(`${this.addressesUrlValue}/${id}.json`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((address) => {
          this.shippingFirstNameTarget.value = address.first_name;
          this.shippingLastNameTarget.value = address.last_name;
          this.shippingCompanyTarget.value = address.company;
          // this.shippingVatNumberTarget.value = address.vat_number;
          this.shippingStreetTarget.value = address.street;
          this.shippingStreetInformationTarget.value =
            address.street_information;
          this.shippingZipcodeTarget.value = address.zipcode;
          this.shippingCityTarget.value = address.city;
          this.shippingStateTarget.value = address.state;
          this.shippingCountryTarget.value = address.country;
          this.shippingPhoneTarget.value = address.phone;

          this.update();
        });
    }
  }

  useShippingAddress() {
    this.billingFirstNameTarget.value = this.shippingFirstNameTarget.value;
    this.billingLastNameTarget.value = this.shippingLastNameTarget.value;
    this.billingCompanyTarget.value = this.shippingCompanyTarget.value;
    this.billingStreetTarget.value = this.shippingStreetTarget.value;
    this.billingStreetInformationTarget.value =
      this.shippingStreetInformationTarget.value;
    this.billingZipcodeTarget.value = this.shippingZipcodeTarget.value;
    this.billingCityTarget.value = this.shippingCityTarget.value;
    this.billingStateTarget.value = this.shippingStateTarget.value;
    this.billingCountryTarget.value = this.shippingCountryTarget.value;
    this.billingPhoneTarget.value = this.shippingPhoneTarget.value;

    if (this.hasShippingVatNumberTarget) {
      this.billingVatNumberTarget.value = this.shippingVatNumberTarget.value;
    }
  }
}
