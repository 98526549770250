import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="stores--admins"
export default class extends Controller {
  static targets = ["availableAdminsSelect"];

  connect() {
    this.availableAdminsSelect = new TomSelect(
      this.availableAdminsSelectTarget
    );
  }

  disconnect() {
    this.availableAdminsSelect.destroy();
  }
}
