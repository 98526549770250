import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="layout"
export default class extends Controller {
  toggleMenu(event) {
    const menu = event.target;
    const toggleIcon = menu.querySelector("svg");
    const subMenu = menu.nextElementSibling;

    toggleIcon.classList.toggle("rotate-90");
    subMenu.classList.toggle("hidden");
  }
}
